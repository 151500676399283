@use "breakpoints";
@use "fonts";

.container {
  padding: 16px 40px;
  background: var(--green);

  @include breakpoints.for-tablet {
    padding: 24px;
  }

  > .message {
    font-size: 18px;
    line-height: 29px;
    color: white;

    @include breakpoints.for-tablet {
      font-size: 16px;
      line-height: 26px;
    }
  }
}