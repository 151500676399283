@use "breakpoints";
@use "responsive";

.container {
  justify-content: center;

  @include breakpoints.for-tablet {
    width: 100%;
    justify-content: space-evenly;
    background: var(--rcsa-white);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    padding: 4px;
  }

  &.darkContainer {
    @include breakpoints.for-tablet {
      background: #052322;
    }
  }
}

.button {
  transition: all 0.4s ease-out;
  user-select: none;
  padding: 10px 32px;
  border-radius: 50px;

  font-size: 15px;
  line-height: 18px;
  text-align: center;
  font-weight: 400;
  color: var(--green);
  background-color: transparent;

  @include breakpoints.for-tablet {
    font-size: responsive.tablet-horizontal(13);
    line-height: 16px;

    padding: 13px responsive.tablet-horizontal(25.5);
  }

  &:hover {
    background-color: var(--yellow-hover4);

    &.darkButton {
      @include breakpoints.for-tablet {
        background-color: var(--green);
      }
    }
  }

  &.selected {
    background-color: var(--yellow-hover4);
    font-weight: 700;

    &.darkButton {
      @include breakpoints.for-tablet {
        background-color: var(--green);
      }
    }
  }

  &.darkButton {
    color: var(--rcsa-white) !important;
  }
}
