@use "breakpoints";

.panel {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background: var(--green);
  width: 423px;
  border-radius: 17.5556px 0 0 17.5556px;
  box-shadow: 0 0.877778px 43.8889px rgba(0, 0, 0, 0.1);
  z-index: var(--cart-zIndex);

  @include breakpoints.for-tablet {
    width: 100%;
    height: var(--app-height);
    border-radius: 0;
  }

  .header {
    padding: 0 26px;

    @include breakpoints.for-tablet {
      padding: 0 32px;
    }
  }
}

.clippyText {
  font-size: 21.0667px;
  line-height: 29px;
  color: var(--bg-color);
  font-weight: 700;
}

.separator {
  width: 100%;
  height: 1px;
  opacity: 0.5;
  background: var(--border);
}

.note {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  font-size: 11px;
  line-height: 18px;
  color: var(--rcsa-white);
  padding: 19px 16px;
}

.closeButton {
  border: 1px solid var(--border);
  border-radius: 50px;

  font-size: 13px;
  line-height: 16px;
  color: var(--border);

  padding: 10px 32px;
  margin: 0 auto;

  transition: background-color 0.4s ease-out;
  user-select: none;

  &:hover {
    background-color: var(--yellow-hover);
  }
}

.clippy {
  background: var(--bg-color);
  width: 72px;
  height: 72px;
  border-radius: 50%;
}

.content {
  overflow-y: auto;
  padding: 0 22px 0 21px;

  @include breakpoints.for-tablet {
    padding: 0 22px 0 26px;
  }
}


.button {
  background: rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 16px 8px 16px 12.94px;
  font-size: 13px;
  line-height: 21px;
  color: var(--rcsa-white);
  cursor: pointer;
  user-select: none;
  transition: background 0.4s ease-out;

  .buttonArrow {
    transform: rotate(-90deg);
  }

  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }
}

.version {
  font-size: 13px;
  line-height: 21px;
  color: white
}
