.container {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid var(--green);
  background: var(--green);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;

  &.darkContainer {
    background: var(--rcsa-white);
  }
}
