$breakpoint-tablet: 1100px;

@mixin for-tablet {
  @media (max-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin for-desktop {
  @media (min-width: $breakpoint-tablet + 1) {
    @content;
  }
} ;
