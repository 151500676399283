@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter/Inter-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter/Inter-Light.ttf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter/Inter-Bold.ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MagicUI Pro";
  src: url("/fonts/MagicUI Pro/MagicUiPro-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MagicUI Pro";
  src: url("/fonts/MagicUI Pro/MagicUiPro-Bold.ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Utopia Semibold";
  src: url("/fonts/Utopia Semibold/Utopia-Semibold.ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

$inter: "Inter", sans-serif;
$magicUI: "MagicUI Pro", sans-serif;
$utopiaSemibold: "Utopia Semibold", sans-serif;