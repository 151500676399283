@use "breakpoints";

.account {
  padding: 4px;
  border-radius: 50px;
  background: white;

  .balance {
    font-size: 15px;
    line-height: 18px;
    color: var(--green);

    @include breakpoints.for-tablet {
      font-size: 13px;
      line-height: 16px;
    }
  }
}

.connectWalletButton {
  cursor: pointer;
  border: 1px solid var(--green);
  color: var(--green);
  font-size: 15px;
  line-height: 18px;
  padding: 8px 32px;
  border-radius: 40px;
  background: rgba(7, 57, 55, 0.2);
  transition: background .4s ease-out;

  &:hover {
    background: var(--yellow-hover);
  }

  &.connectWalletDarkButton {
    color: var(--rcsa-white);
    background: rgba(255, 251, 242, 0.2);

    &:hover {
      background: var(--yellow-hover4);
    }
  }
}

.accountInfo {
  padding: 7px 10.24px;
  border-radius: 32px;
  background: rgba(201, 196, 189, 0.5);
  color: var(--green);
  font-size: 15px;
  line-height: 18px;

  @include breakpoints.for-tablet {
    font-size: 13px;
    line-height: 16px;
  }
}

.blockie {
  border-radius: 50%;
}
