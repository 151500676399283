@use "fonts";
@use "breakpoints";

.container {
  position: relative;
}

.main {
  min-height: calc(100vh - 90px);
  background: var(--bg-color);

  @include breakpoints.for-tablet {
    min-height: calc(var(--app-height) - 72px);
  }
}

.title {
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  font-family: fonts.$inter;
  font-weight: 700;

  @include breakpoints.for-tablet {
    font-size: 18px;
    line-height: 22px;
  }
}

.inputContainer {
  width: 800px;
  border: 1px solid var(--border);
  border-radius: 50px;
  background-color: var(--bg-color);
  padding: 0 16px;
  transition: background-color 0.5s;

  @include breakpoints.for-tablet {
    width: calc(100vw - 48px)
  }

  &:hover {
    background-color: var(--rcsa-white);
  }

  .input {
    flex: 1;
    border: none;
    background: transparent;
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
    color: var(--green);
    padding: 18px 16px;

    &::placeholder {
      color: var(--green);
    }

    &:focus {
      outline: none;
    }
  }

  &.inputFocusingContainer {
    background-color: var(--green);

    .input {
      color: var(--bg-color);
      &::placeholder {
        color: var(--bg-color);
      }
    }
  }

  .searchIcon {
    filter: invert(16%) sepia(71%) saturate(490%) hue-rotate(129deg) brightness(93%) contrast(97%);

    &.focusing {
      filter: invert(91%) sepia(3%) saturate(1773%) hue-rotate(323deg) brightness(111%) contrast(94%);
    }
  }
}

.text {
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: var(--green);

  @include breakpoints.for-tablet {
    font-size: 13px;
    line-height: 16px;
  }
}

.menuButton {
  transition: background-color 0.4s ease-out;
  user-select: none;
  padding: 10px 32px;
  border-radius: 50px;

  &:hover {
    background-color: var(--yellow-hover3);
  }
}

.pleaseUseComputer {
  font-size: 16px;
  line-height: 22px;
  color: var(--green);
  text-align: center;

  @include breakpoints.for-tablet {
    padding: 0 36px;
  }
}

.seeHowItWorks {
  font-size: 16px;
  line-height: 19px;
  color: var(--green);
  opacity: 0.4;
  cursor: pointer;
}
