@use "fonts";
@use "breakpoints";

.title {
  font-family: fonts.$inter;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: var(--green);

  @include breakpoints.for-tablet {
    font-size: 18px;
    line-height: 22px;
  }
}

.connectedWallet {
  font-size: 13px;
  line-height: 21px;
  color: var(--green);

  @include breakpoints.for-tablet {
    font-size: 11px;
    line-height: 18px;
  }
}

.account {
  background: var(--border-opacity-50);
  border: 1px solid var(--border);
  border-radius: 10px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .accountAddress {
    font-size: 24px;
    line-height: 38px;
    font-weight: 700;
    color: var(--green);

    @include breakpoints.for-tablet {
      font-size: 16px;
      line-height: 26px;
    }
  }

  .accountBalance {
    font-size: 14px;
    line-height: 17px;
    color: var(--green);

    @include breakpoints.for-tablet {
      font-size: 11px;
      line-height: 13px;
    }
  }
}

.blockie {
  border-radius: 50%;
}

.button {
  border-radius: 50px;
  cursor: pointer;
  padding: 8px 12px;
  transition: background .4s ease-out;
  font-size: 14px;
  line-height: 17px;
  color: var(--green);

  @include breakpoints.for-tablet {
    font-size: 11px;
    line-height: 13px;
  }

  &:hover {
    background: var(--yellow-hover);
  }
}

.disconnectButton {
  background: var(--green);
  padding: 16px;
  text-align: center;
  color: var(--rcsa-white);
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
  cursor: pointer;
  margin: 0 -24px -24px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  @include breakpoints.for-tablet {
    font-size: 13px;
    line-height: 21px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
