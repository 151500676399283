@use "fonts";
@use "breakpoints";

.content {
  width: 1300px !important;
  padding: 0 !important;

  @include breakpoints.for-tablet {
    width: 100% !important;
  }
}

.getStartedContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  @include  breakpoints.for-tablet {
    max-height: 90vh;
  }

  .getStartedText {
    font-family: fonts.$inter;
    font-weight: 700;
    font-size: 28px;
    line-height: 48px;
    color: var(--green);

    @include breakpoints.for-tablet {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .leftPanel {
    flex: calc(1 - 585/1300);
    height: 720px;

    @include breakpoints.for-tablet {
      flex: 0;
      height: unset;
    }

    .leftPanelContent {
      padding: 0 77px 0 40px;
      flex: 1;
      overflow: hidden;

      @include breakpoints.for-tablet {
        padding: 24px;
      }
    }
  }

  .rightPanel {
    flex: calc(715/1300);
    background-color: var(--green-10);
    padding: 0 0 0 64px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > .closeButton {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  div.stepContainer {
    transition: all 0.4s ease-out;
    margin: 20px 0;

    &.stepContainerExpanded {
      background: var(--bg-color);
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.25);
      border-radius: 10px;

      > div.stepText {
        padding: 40px 24px 0 24px;

        @include breakpoints.for-tablet {
          padding: 24px 24px 0;
        }
      }
    }

    > div.stepText {
      padding: 0 24px 25px;
      font-family: fonts.$inter;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: var(--green);
      cursor: pointer;
      user-select: none;

      @include breakpoints.for-tablet {
        padding: 0 24px 16px;
        font-size: 13px;
        line-height: 16px;
      }
    }

    div.stepContent {
      padding: 6px 24px 22px 6px;
      color: var(--green);
      font-size: 17px;
      line-height: 29px;

      @include breakpoints.for-tablet {
        font-size: 13px;
        line-height: 21px;
      }

      u {
        text-underline-offset: 3px;
      }
    }

    > div.stepSeparator {
      background-color: var(--border-opacity-50);
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.25);
      height: 1px;
      width: 100%;
    }
  }
}

div.getStartedFooter {
  padding: 16px 24px;
  background-color: var(--green);
  width: 100%;

  @include breakpoints.for-tablet {
    padding: 24px 0;
  }

  > span.learnMoreMessage {
    font-family: fonts.$inter;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: var(--green-white);
  }
}

.mediaContainer {
  width: 100%;
  height: 321px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--border-opacity-50);
}

.browserAndVideoContainer {
  max-width: 651px;
  width: 651px;
  height: 366px;

  @include breakpoints.for-tablet {
    max-width: 356px;
    width: 356px;
    height: 200px;
  }

  .videoContainer {
    margin-top: -6px;

    video {
      width: 100%;
      height: 100%;
      border-radius: 0 0 11px 11px;
      background: black;
    }
  }
}
