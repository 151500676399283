@use "fonts";
@use "breakpoints";

.container {
  transition: box-shadow 0.4s ease-out, background-color 0.1s ease-out;
  position: sticky;
  top: 0;
  width: 100%;

  z-index: var(--header-menu-zIndex);

  &.darkContainer {
    background: var(--green);
  }

  &.scrolled {
    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.15);
    z-index: var(--header-menu-zIndex);

    background: var(--bg-color);

    &.darkContainer {
      background: var(--green);
    }
  }
}

.menu {
  padding: 26px 40px;
  height: 90px;

  @include breakpoints.for-tablet {
    padding: 16px 24px;
    height: 72px;
  }
}

.button {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid var(--green);
  background: var(--green);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;

  &.darkButton {
    background: var(--rcsa-white);
  }
}

.menuContainer {
  position: fixed;
  bottom: 40px;
  align-self: center;
  z-index: 2;
}
